import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Main from "./pages/Main";
import {Route, Routes} from "react-router-dom";
import Project from "./pages/Project";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdtHTmQA0aiIJAku0sl37NCDgkWzBwK3A",
  authDomain: "frontline-official.firebaseapp.com",
  projectId: "frontline-official",
  storageBucket: "frontline-official.appspot.com",
  messagingSenderId: "883544850624",
  appId: "1:883544850624:web:6c1cc0ff8f9c4c8ace2d25",
  measurementId: "G-KH47YLY19B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  return (
      <Routes>
        <Route path={'/'} element={<Main/>}/>
        <Route path={'/project/:projectTitle'} element={<Project/>}/>
      </Routes>
  );
}

export default App;
