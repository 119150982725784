import React from 'react'

import styles from './style.module.scss'
import Logo from "components/Logo";
import {Avatar, ConfigProvider, List, Space, Tag, Typography} from "antd";

import {Data} from 'data'
import {LinkOutlined} from "@ant-design/icons";


const Main = () => {

    return <div className={styles.MainContainer}>
        <ConfigProvider theme={{token: {colorPrimary: "#fff", colorText: "#fff"}}}>
            <div className={styles.Section}>
                <div className={styles.LogoBackground}>

                    <Logo.Logo/>
                </div>
                <div className={styles.ContentsWrapper}>
                    <Typography.Title level={1} style={{textAlign: 'center'}}>
                        Your Business Front Partner
                    </Typography.Title>

                    <div style={{textAlign: 'center'}}>
                        <Typography.Title level={2}>
                            We are Front Liners<br/>
                        </Typography.Title>
                    </div>
                    <div style={{textAlign: 'right', marginTop: 50}}>

                        <Typography.Text>
                            IoT<br/>
                            Web and App Service<br/>
                            Data Labeling and AI
                        </Typography.Text>
                    </div>

                </div>
            </div>
        </ConfigProvider>

        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#fff",
                    colorText: "#fff",
                    colorLink: "#fff",
                    colorTextBase: "#fff",
                    colorBorder: '#fff'
                }
            }}>
            <div
                className={styles.Section}
                style={{
                    backgroundColor: "#333"
                }}
            >
                <div className={styles.LogoBackground}>
                    <Logo.Logo/>
                </div>
                <div className={styles.ContentsWrapper}>
                    <Typography.Title level={1}>
                        Projects
                    </Typography.Title>


                    <List
                        footer={<Typography.Link
                            href="https://docs.google.com/document/d/1oe_Bj_SkVWcunYXg6P-oxJuSQz_DtLhZLR8i5X41rio/edit?usp=sharing">
                            ...More</Typography.Link>}
                        dataSource={Data?.projects}
                        renderItem={(project, index) => (<List.Item
                                key={project.title}
                                actions={[
                                    project.skills.map(skill => (<Tag>{skill}</Tag>))
                                ]}
                                extra={
                                    project.link && <Typography.Link href={project.link}>
                                        <LinkOutlined/>
                                    </Typography.Link>
                                }
                            >
                                <List.Item.Meta
                                    title={<a href={project.link}>{project.title}</a>}
                                    description={project.description}
                                />
                                {project.content}
                            </List.Item>
                        )}
                    />
                </div>

            </div>
        </ConfigProvider>

        <ConfigProvider theme={{token: {colorPrimary: "#333", colorText: "#fff", colorLink: '#fff'}}}>
            <div
                className={styles.Section}
                style={{
                    backgroundColor: "#df1b4f"
                }}
            >
                <div className={styles.LogoBackground}>
                    <Logo.LogoWhite/>
                </div>
                <div className={styles.ContentsWrapper}>
                    <Typography.Title level={1}>
                        Information
                    </Typography.Title>
                    <Typography.Title level={2}>
                        Location
                    </Typography.Title>
                    <Typography.Link href="https://goo.gl/maps/FHHBEwYshK2h86WEA">
                        서울시 성북구 화랑로 299, 석계 한일노벨리아시티<br/>
                        299, Hwarang-ro, Seongbuk-gu, Seoul, 02779, Rep. of KOREA
                    </Typography.Link>
                    <Typography.Title level={2}>
                        Contact
                    </Typography.Title>
                    <Typography.Link href="mailto:contact@frontli.ne.kr">
                        contact@frontli.ne.kr
                    </Typography.Link>


                </div>
            </div>

            <div className={styles.Footer}>
                    <Typography.Text>
                        프론트라인 Front-Line | 사업자 등록 번호 : 746-10-01628 | 대표 : 주민범<br/>
                        © Front-Line Corp. All rights reserved.
                    </Typography.Text>
            </div>
        </ConfigProvider>
    </div>
}

export default Main
