import moment from "moment";

export const Data = {
    projects: [
        {
            title: 'HiQSum',
            description: "대화형 문제 풀이 어플 '하이큐썸'의 문제 데이터 엔지니어링, 웹 및 앱 서비스 개발",
            specs: [''],
            skills: ['React', 'React Native', 'express.js', 'Google Cloud Platform'],
            date: [moment(), moment()],
            link: 'https://hiqsum.com/',
            client: {name: '', link: '', logo: ''}
        },
        {
            title: 'B4Play Influence Center',
            description: "인플루언서를 위한 게임 데이터 센터 구축 및 데이터 엔지니어링",
            specs: [''],
            skills: ['React', 'Python', 'Flask', 'Nivo Chart', 'Amazon Web Service'],
            date: [moment(), moment()],
            link: 'https://hiqsum.com/',
            client: {name: '', link: '', logo: ''}
        },
        {
            title: 'ZER01NEDAY 2022',
            description: '현대 모터 그룹에서 지원하는 ZER01NE 프로젝트의 온라인 전시 홈페이지와 사전예약',
            specs: [''],
            skills: ['React', 'Recoil', 'express.js', 'PostgreSQL'],
            date: [moment(), moment()],
            link: 'https://zer01neday.com/2022',
            client: {name: '', link: '', logo: ''}
        },
        {
            title: 'Nike By Hongdae',
            description: '나만의 티셔츠를 직접 커스텀할 수 있는 서비스',
            specs: [''],
            skills: ['React', 'Python', 'Fabric'],
            date: [moment(), moment()],
            client: {name: '', link: '', logo: ''}
        },
        {
            title: 'NIKE SHOE FINDER',
            description: '연령과 성별, 그리고 취향에 따라서 신발을 추천해주는 웹사이트',
            specs: [''],
            skills: ['React', 'Lottie'],
            date: [moment(), moment()],
            client: {name: '', link: '', logo: ''}
        },
        {
            title: 'Wadiz Store Back Office',
            description: '와디즈 스토어 관리자 화면 개발',
            specs: [''],
            skills: ['React', 'ant design'],
            date: [moment(), moment()],
            client: {name: '', link: '', logo: ''}
        }
    ]
}
